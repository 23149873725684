import DangerousHtmlContent from 'components/common/DangerousHtmlContent';
import { ErrorMessage } from 'components/typography';
import { InformationModal } from 'components/legacy/modals/InformationModal';
import { Spacer } from 'components/layouts/Spacer';
import { Heading } from 'components/typography';
import { useLatestNewsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useModal } from 'lib/useModal';
import { useRouter } from 'next/router';
import { NewsFeedLoader } from './NewsFeed.loader';
import * as S from './NewsFeed.styles';
import { Button } from 'components/common/buttons/Button';

export const NewsFeed = ({ name }: { name: string }) => {
  const news = useLatestNewsQuery();
  const router = useRouter();
  const dive = useDive();

  const regExp = /hiberworld|teamhiber|hiberdev/g;
  const isInternalLink = regExp.exec(news.data?.latestNewsArticle?.ctaButtonLink ?? '');
  const linkTarget = isInternalLink ? '_self' : '_blank';
  const trackId = news.data?.latestNewsArticle?.title;
  const latestNewsArticle = news.data?.latestNewsArticle;

  const navigate = () => {
    if (!latestNewsArticle?.ctaButtonLink) {
      return;
    }

    if (isInternalLink) {
      router.push(latestNewsArticle.ctaButtonLink);
      return;
    }

    window.open(latestNewsArticle.ctaButtonLink, linkTarget);
  };

  const onModalConfirm = () => {
    if (!latestNewsArticle?.ctaButtonLink) {
      return;
    }
    navigate();
  };

  const infoModal = useModal(
    <InformationModal
      headline={news.data?.latestNewsArticle?.modalTitle}
      confirmButtonText={news.data?.latestNewsArticle?.modalButtonText}
      onConfirm={onModalConfirm}
      trackButtonName={news.data?.latestNewsArticle?.title}>
      <DangerousHtmlContent html={news.data?.latestNewsArticle?.modalText} />
    </InformationModal>
  );

  if (news.error) {
    return <ErrorMessage message={news.error.message} />;
  }

  if (news.loading) {
    return <NewsFeedLoader />;
  }

  if (!latestNewsArticle) {
    return null;
  }

  const handleButtonClick = () => {
    dive.trackUserInteract(router.asPath, '', name, 'button', trackId);
    if (latestNewsArticle.openModal) {
      infoModal.open();
      return;
    }
    navigate();
  };

  const handleClick = () => {
    if (latestNewsArticle.openModal) {
      return;
    }

    handleButtonClick();
  };

  return (
    <S.NewsContainer aria-label="News container">
      <S.ImageContainer onClick={handleClick}>
        <S.NewsImage src={latestNewsArticle.thumbnail?.url ?? ''} height={400} width={700} alt="News" priority />
      </S.ImageContainer>
      <S.TextContainer justifyContent="center" direction="column" padding="1rem 4rem" alignItems="flex-start">
        <Heading size="h2" as="h2" onClick={handleClick}>
          {latestNewsArticle.title}
        </Heading>
        <Spacer height="1rem" />
        <DangerousHtmlContent html={latestNewsArticle.article} />
        <Spacer height="1rem" />
        <Button
          variety="primary"
          size="medium"
          text={latestNewsArticle.ctaButtonText ?? ''}
          onClick={handleButtonClick}
        />
      </S.TextContainer>
    </S.NewsContainer>
  );
};

export default NewsFeed;
