import { SessionCard } from 'components/cards/SessionCard';
import { SessionCardLoader } from 'components/cards/SessionCard/SessionCard.loader';
import { useSessionsByProjectsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { useRouter } from 'next/router';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import * as S from './SessionFeed.styles';
import { shuffleArray } from 'utils/shuffleArray';
import { useTheme } from 'styled-components';
import { useFeatureToggle } from 'lib/useFeatureToggles';
import { FEATURE_TOGGLE_PINNED_WORLDS } from 'constants/featureToggle.constants';

const NUMBER_OF_SESSIONS = 10;

export const SessionFeed = ({
  module,
  name,
  firstImageHasPriority = false,
}: {
  module: PageModuleTypeProp<'SessionFeed'>;
  name: string;
  firstImageHasPriority?: boolean;
}) => {
  const showPinnedWorlds = useFeatureToggle(FEATURE_TOGGLE_PINNED_WORLDS);

  const limit = module.quantity ?? NUMBER_OF_SESSIONS;
  const projectIds = module.liveEvents.map(project => Base64.toNumber(project.worldId).toString());
  const { loading, data } = useSessionsByProjectsQuery({
    variables: { limit, projectIds, showPinnedWorlds: showPinnedWorlds.isEnabled ?? false },
    nextFetchPolicy: 'standby',
    skip: showPinnedWorlds.loading, // Remove with showPinnedWorlds feature toggle (avoids two requests).
  });

  const theme = useTheme();
  const router = useRouter();
  const { trackUserInteract } = useDive();

  const trackSessionCardClick = (projectId: string, destination: string) => {
    trackUserInteract(destination, router.asPath, name, 'feed', projectId);
  };

  // Extract most popular project from query result and filter out locked worlds
  const publicVisibility = 2;
  const mostPopularProjects =
    data?.mostPopularProjectsV2.filter(project => !project.isRestricted && project.visibility === publicVisibility) ||
    [];

  const shuffledProjects = shuffleArray(
    mostPopularProjects,
    mostPopularProjects.map(p => `${p.id}${p.numberOfActivePlayersV2}`).join('')
  );

  const projects = [...(data?.projectsByIds ?? []), ...shuffledProjects];

  if (!loading && projects.length === 0) {
    return null;
  }

  return (
    <>
      <FeedHeading heading={module.title} />
      <S.Sessions>
        {loading
          ? [...Array(limit)].map((_, index) => <SessionCardLoader key={`${index}-playing-right-now-load-card`} />)
          : projects.map((projectSession, index) => {
              const isLiveEvent =
                !('numberOfActivePlayersV2' in projectSession) || !projectSession.numberOfActivePlayersV2;
              const label = isLiveEvent
                ? module.liveEvents[index]?.label
                : `${projectSession.numberOfActivePlayersV2} playing`;
              const color = isLiveEvent ? theme.primaryColor.red : theme.primaryColor.green;

              return (
                <SessionCard
                  imageHasPriority={firstImageHasPriority && index === 0}
                  thumbnailUrl={projectSession.thumbnail?.medium.url ?? ''}
                  label={label}
                  projectId={projectSession.id}
                  projectTitle={projectSession.title}
                  dotColor={color}
                  key={`playing-right-now-${projectSession.id}`}
                  onSessionClick={() => trackSessionCardClick(projectSession.id, ENGINE_TRACK_URL)}
                  onProjectClick={() =>
                    trackSessionCardClick(projectSession.id, `/world/${Base64.fromNumber(projectSession.id)}`)
                  }
                />
              );
            })}
      </S.Sessions>
    </>
  );
};

export default SessionFeed;
