import { Play } from '@hiberworld/icons';
import { FlexBox } from 'components/layouts/FlexBox';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import Link from 'next/link';
import Base64 from 'utils/Base64';
import * as S from './SessionCard.styles';
import { SessionCardProps } from './SessionCard.types';
import { OnlinePlayers } from './OnlinePlayers';

export const SessionCard = ({
  label,
  projectTitle,
  projectId,
  thumbnailUrl,
  onSessionClick,
  onProjectClick,
  imageHasPriority = false,
  dotColor,
}: SessionCardProps) => {
  const { loadAndJoinSession, loading } = useGetOrCreateDaoSession();

  const handleCardClick = () => {
    if (loading) {
      return;
    }
    loadAndJoinSession(projectId);
    onSessionClick?.();
  };

  return (
    <S.Wrapper data-testid="session-card">
      <S.SessionContainer onClick={handleCardClick} role="button" data-testid="session-card-join">
        <S.Thumbnail
          src={thumbnailUrl}
          alt="Click to join"
          height={256}
          width={455}
          data-testid="image-background"
          priority={imageHasPriority}
        />
        <FlexBox
          pointerEvents="none"
          width="100%"
          position="absolute"
          direction="column"
          justifyContent="space-between"
          height="100%">
          <OnlinePlayers label={label} color={dotColor} />
          <S.PlayContainer>
            <S.SmallButton
              variety="primary"
              size="small"
              Icon={<Play />}
              busy={loading}
              text="Join"
              disabled={loading}
            />
          </S.PlayContainer>
        </FlexBox>
      </S.SessionContainer>

      <S.TitleContainer>
        <Link href={`/world/${Base64.fromNumber(projectId)}`} passHref prefetch={false} title={projectTitle}>
          <S.Title size="large" onClick={onProjectClick}>
            {projectTitle}
          </S.Title>
        </Link>
      </S.TitleContainer>
    </S.Wrapper>
  );
};
