import { FlexBox } from 'components/layouts/FlexBox';
import { Spacer } from 'components/layouts/Spacer';
import { Heading, Paragraph } from 'components/typography';
import { BannerLayout } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { useGetOrCreateDaoSession } from 'hooks/useGetOrCreateDaoSession';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import * as S from './Banner.styles';
import { Button } from 'components/common/buttons/Button';

export const Banner = ({
  module,
  name,
  imageHasPriority = false,
}: {
  module: PageModuleTypeProp<'Banner'>;
  name: string;
  imageHasPriority?: boolean;
}) => {
  const { header, text, background, buttonLabel, projectIdOrButtonLink, bannerLayout } = module;
  const router = useRouter();
  const session = useGetOrCreateDaoSession();
  const [width, setWidth] = useState(0);
  const { trackUserInteract } = useDive();
  const layout = bannerLayout ?? BannerLayout.Large;
  const isSmallBanner = layout === BannerLayout.Small;

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handleButton = () => {
    const isExternalLink = projectIdOrButtonLink?.startsWith('https://');
    const isInternalLink = projectIdOrButtonLink?.startsWith('/');

    trackUserInteract(projectIdOrButtonLink, router.asPath, name, 'banner', '');

    if (isInternalLink && projectIdOrButtonLink) {
      router.push(projectIdOrButtonLink);
      return;
    }
    if (isExternalLink && projectIdOrButtonLink) {
      window.open(projectIdOrButtonLink, '_blank');
      return;
    }

    session.loadAndJoinSession(Base64.toNumber(projectIdOrButtonLink).toString());
  };

  const titleHasLongWords = header
    .split(' ')
    .map(word => word.length > 9)
    .some(isLong => isLong);

  const smallerHeadline = isSmallBanner || (width < 450 && titleHasLongWords);

  return (
    <S.Wrapper layout={layout}>
      <S.ImageWrapper>
        <S.ImageOverlay />
        <S.BackgroundImage src={background?.url ?? ''} fill alt="Banner image" priority={imageHasPriority} />
      </S.ImageWrapper>
      <S.InformationContainer layout={layout}>
        <div>
          {smallerHeadline ? (
            <Heading size="h2" as="h1" maxWidth={isSmallBanner ? '350px' : ''}>
              {header}
            </Heading>
          ) : (
            <Heading size="h1">{header}</Heading>
          )}
          {text && (
            <div style={{ maxWidth: '80%' }}>
              <Spacer height="1rem" />
              <Paragraph size="medium">{text}</Paragraph>
            </div>
          )}
        </div>
        <Spacer height="1rem" />
        <FlexBox alignItems="flex-end" height="100%">
          <Button
            variety="primary"
            size="medium"
            onClick={handleButton}
            disabled={session.loading}
            busy={session.loading}>
            {buttonLabel}
          </Button>
        </FlexBox>
      </S.InformationContainer>
    </S.Wrapper>
  );
};

export default Banner;
