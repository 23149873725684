import { ErrorMessage } from 'components/typography';
import { ProjectCard } from 'components/cards/ProjectCard';
import { FeedFilter, useProjectsQuery, useRecommendationsQuery, useSocialFeedQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { useRouter } from 'next/router';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import { ProjectsGrid } from './ProjectFeed.styles';
import { ProjectFeedLoader } from './ProjectFeedLoader';

export const ProjectFeedRegularModule = ({
  module,
  name,
}: {
  module: PageModuleTypeProp<'ProjectFeedRegular'>;
  name: string;
}) => {
  const filterFollowing =
    module.page?.filters?.includes(FeedFilter.Following) || module.filters?.includes(FeedFilter.Following);
  const isRecommendedFeed =
    module.page?.filters?.includes(FeedFilter.Recommended) || module?.filters?.includes(FeedFilter.Recommended);
  const { trackUserInteract } = useDive();
  const router = useRouter();

  const payload = {
    first: module.quantity,
    sortBy: module.sortBy,
    minEngineVersion: 3,
    maxEngineVersion: 3,
    tags: module.page?.tags ?? module?.tags ?? [],
    meta: module.page?.metaTags ?? module?.metaTags ?? [],
  };

  const {
    data: socialData,
    loading: socialLoading,
    error: socialError,
  } = useSocialFeedQuery({
    variables: {
      payload,
      includeOwnGames: false,
    },
    skip: !filterFollowing || isRecommendedFeed,
    notifyOnNetworkStatusChange: true,
  });
  const {
    loading: recommendationsLoading,
    data: recommendationsData,
    error: recommendationError,
  } = useRecommendationsQuery({
    variables: {
      quantity: module.quantity,
    },
    skip: !isRecommendedFeed,
  });
  const {
    loading: projectsLoading,
    data,
    error: projectsError,
  } = useProjectsQuery({
    variables: payload,
    skip: filterFollowing || isRecommendedFeed,
  });

  const trackProjectCardClick = (projectId: string, destination: string) => {
    trackUserInteract(destination, router.asPath, name, 'feed', projectId);
  };

  const loading = recommendationsLoading || socialLoading || projectsLoading;
  const error = recommendationError || socialError || projectsError;

  if (loading) {
    return <ProjectFeedLoader quantity={module.quantity} layout={module.layout} />;
  }

  if (error) {
    return <ErrorMessage message={error?.message} />;
  }
  const projects = [
    ...(data?.projects?.edges ?? []).map(edge => edge.node),
    ...(socialData?.socialFeed?.items ?? []),
    ...(recommendationsData?.recommendations.items ?? []),
  ];

  const slug = module.page?.slug;
  const viewMoreLink = slug ? `/feed/${slug}/${module.sortBy.toLowerCase()}` : undefined;

  if (projects.length > 0) {
    return (
      <>
        <FeedHeading heading={module.title} viewMoreLink={viewMoreLink} />
        <ProjectsGrid layout={module.layout}>
          {projects.map(project => (
            <ProjectCard
              project={project}
              key={project.id}
              playOnClick={module.directPlayOnClick}
              showAuthor={Boolean(module.showAuthor)}
              onAuthorClick={() => trackProjectCardClick(project.id, `/user/${project.author.username}`)}
              onProjectClick={() =>
                trackProjectCardClick(
                  project.id,
                  module.directPlayOnClick ? ENGINE_TRACK_URL : `/world/${Base64.fromNumber(Number(project.id))}`
                )
              }
            />
          ))}
        </ProjectsGrid>
      </>
    );
  }

  return null;
};

export default ProjectFeedRegularModule;
