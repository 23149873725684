import Image from 'next/image';
import styled from 'styled-components';

export const BannerContainer = styled.div`
  max-height: 70vh;
  height: 50vh;
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.small}) {
    padding: 0 4rem;
  }
`;

export const HeroImage = styled(Image)`
  width: 100%;
  object-fit: cover;
  object-position: 0% 40%;
`;

export const Gradient = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(27, 28, 32, 0.4), rgba(27, 28, 32, 0.4));
  opacity: 1;

  @media (min-width: ${({ theme }) => theme.breakpoint.small}) {
    background: linear-gradient(86deg, rgb(27, 28, 35, 0.5) 0%, rgba(41, 42, 49, 0.15) 100%);
  }
`;
