import { ErrorMessage } from 'components/typography';
import { ProjectCard } from 'components/cards/ProjectCard';
import { Project, useProjectsByIdsQuery } from 'generated/graphql';
import { useDive } from 'hooks/useDive';
import { ENGINE_TRACK_URL } from 'hooks/useDive/useDive.constants';
import { useRouter } from 'next/router';
import Base64 from 'utils/Base64';
import { PageModuleTypeProp } from '../PageModule.types';
import { FeedHeading } from '../shared/FeedHeading';
import { ProjectsGrid } from './ProjectFeed.styles';
import { ProjectFeedLoader } from './ProjectFeedLoader';

export const ProjectFeedSpecificModule = ({
  module,
  name,
}: {
  module: PageModuleTypeProp<'ProjectFeedSpecific'>;
  name: string;
}) => {
  const projectIds = module.projectIds.map(id => Base64.toNumber(id).toString());
  const { loading, error, data } = useProjectsByIdsQuery({
    variables: { projectIds },
  });
  const router = useRouter();
  const { trackUserInteract } = useDive();
  const trackProjectCardClick = (projectId: string, destination: string) => {
    trackUserInteract(destination, router.asPath, name, 'feed', projectId);
  };

  if (loading) {
    return <ProjectFeedLoader quantity={projectIds.length} layout={module.layout} />;
  }

  if (error) {
    return <ErrorMessage message={error?.message} />;
  }

  const projectsByIds = data?.projectsByIds;

  // Projects are not fetch in the order of the ids, so this sorts them correctly again
  const sortedProjects = (projectIds || [])
    .map(id => projectsByIds?.find((project: Project) => project.id === id))
    .filter((project: Project | undefined): project is Project => project !== undefined);

  if (sortedProjects.length > 0) {
    return (
      <>
        <FeedHeading heading={module.title} />
        <ProjectsGrid layout={module.layout}>
          {sortedProjects.map(project => (
            <ProjectCard
              project={project}
              key={project.id}
              playOnClick={module.directPlayOnClick}
              showAuthor={Boolean(module.showAuthor)}
              onProjectClick={() =>
                trackProjectCardClick(
                  project.id,
                  module.directPlayOnClick ? ENGINE_TRACK_URL : `/world/${Base64.fromNumber(Number(project.id))}`
                )
              }
              onAuthorClick={() => trackProjectCardClick(project.id, `/user/${project.author.username}}`)}
            />
          ))}
        </ProjectsGrid>
      </>
    );
  }

  return null;
};

export default ProjectFeedSpecificModule;
